<template>
  <div class="mod-home">
    <p>一个基于spring boot、spring oauth2.0、mybatis、redis的轻量级、前后端分离的系统</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <h2>前言</h2>
    <p>
      云想沃客小程序内部管理后台系统。
    </p>
    <p>&nbsp;</p>
    <h2>技术选型</h2>
    <figure>
      <table border="1"
             cellspacing="0"
             cellpadding="5px">
        <thead>
          <tr>
            <th>技术</th>
            <th>版本</th>
            <th>说明</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Spring Boot</td>
            <td>2.1.6.RELEASE</td>
            <td>MVC核心框架</td>
          </tr>
          <tr>
            <td>Spring Security oauth2</td>
            <td>2.1.5.RELEASE</td>
            <td>认证和授权框架</td>
          </tr>
          <tr>
            <td>MyBatis</td>
            <td>3.5.0</td>
            <td>ORM框架</td>
          </tr>
          <tr>
            <td>MyBatisPlus</td>
            <td>3.1.0</td>
            <td>基于mybatis，使用lambda表达式的</td>
          </tr>
          <tr>
            <td>Swagger-UI</td>
            <td>2.9.2</td>
            <td>文档生产工具</td>
          </tr>
          <tr>
            <td>Hibernator-Validator</td>
            <td>6.0.17.Final</td>
            <td>验证框架</td>
          </tr>
          <tr>
            <td>redisson</td>
            <td>3.10.6</td>
            <td>对redis进行封装、集成分布式锁等</td>
          </tr>
          <tr>
            <td>hikari</td>
            <td>3.2.0</td>
            <td>数据库连接池</td>
          </tr>
          <tr>
            <td>log4j2</td>
            <td>2.11.2</td>
            <td>更快的log日志工具</td>
          </tr>
          <tr>
            <td>fst</td>
            <td>2.57</td>
            <td>更快的序列化和反序列化工具</td>
          </tr>
          <tr>
            <td>orika</td>
            <td>1.5.4</td>
            <td>更快的bean复制工具</td>
          </tr>
          <tr>
            <td>lombok</td>
            <td>1.18.8</td>
            <td>简化对象封装工具</td>
          </tr>
          <tr>
            <td>hutool</td>
            <td>4.5.0</td>
            <td>更适合国人的java工具集</td>
          </tr>
          <tr>
            <td>swagger-bootstrap</td>
            <td>1.9.3</td>
            <td>基于swagger，更便于国人使用的swagger ui</td>
          </tr>
        </tbody>
      </table>
    </figure>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {}
</script>

<style>
.mod-home {
  line-height: 1.5;
}
</style>

